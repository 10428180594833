<template>
  <div>
    <a
      @click="confirmationBack"
      href="#"
      class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg d-flex flex-row align-items-center"
    >
      <i class="mdi mdi-chevron-left"></i>
      Kembali
    </a>
    <b-row class="justify-content-end">
      <b-col class="d-flex justify-content-end">
        <div
          class="d-flex align-items-center"
          style="position: fixed; top: 2rem; z-index: 1000000"
        >
          <!--begin::Button-->

          <a
            href="#"
            class="btn btn-fh btn-white btn-hover-primary font-weight-bold px-2 px-lg-5 mr-2 border"
          >
            <span class="svg-icon svg-icon-primary svg-icon-lg">
              <!--begin::Svg-->
              <inline-svg src="media/svg/icons/Home/Clock.svg" />
              <!--end::Svg Icon-->
            </span>
            <span v-if="this.now >= this.currentAttempt.target">
              00:00:00
            </span>
            <span v-else>
              {{ hours | two_digits }}:{{ minutes | two_digits }}:{{
                seconds | two_digits
              }}
            </span>
          </a>
          <!--end::Button-->
          <!--begin::Button-->
          <span
            @click="submitUjian"
            class="btn btn-fh btn-light-info btn-hover-info border border-info font-weight-bold px-2 px-lg-5 mr-2"
          >
            <span class="svg-icon svg-icon-info svg-icon-lg">
              <!--begin::Svg-->
              <inline-svg src="media/svg/icons/General/Save.svg" />
              <!--end::Svg Icon-->
            </span>
            Selesai
          </span>
        </div>
      </b-col>
    </b-row>
    <b-row class="h-100 mt-10">
      <b-col xs="12" lg="9" md="12">
        <div :class="`card card-custom card-stretch gutter-b`">
          <div class="card-header border-0 py-5">
            <h3
              class="card-title align-items-start flex-column justify-content-center"
            >
              <span class="card-label font-weight-bolder text-dark">
                Soal {{ selectedIndex + 1 }}
              </span>
              <h6 class="card-label font-weight-bolder text-muted my-2 h-6">
                {{
                  examType === "ices"
                    ? ""
                    : selectedQuestion.type === "multiple answer"
                    ? "Bisa Lebih dari satu jawaban benar"
                    : ""
                }}
              </h6>
            </h3>
            <div class="card-toolbar">
              <a
                :class="`btn btn-icon btn-light-info btn-sm ${
                  selectedQuestion.flagged ? 'active' : ''
                }`"
                @click="changeFlagged"
              >
                <span
                  :class="`svg-icon svg-icon-md ${
                    selectedQuestion.flagged ? 'svg-icon-light' : ''
                  }`"
                >
                  <inline-svg
                    src="media/svg/icons/Communication/Flag.svg"
                  ></inline-svg>
                </span>
              </a>
            </div>
          </div>
          <div class="card-body d-flex flex-column">
            <div class="flex-grow-1 pb-5">
              <div v-html="selectedQuestion.question" class="mb-4"></div>

              <div class="jawaban">
                <template v-if="selectedQuestion.type != 'essay'">
                  <div
                    @click="chooseJawaban(jawaban, selectedQuestion)"
                    :class="`card card-custom card-stretch ${
                      selectedQuestion.type == 'multiple answer' &&
                      selectedQuestion.answer.filter((item) => item.selected)
                        .length == selectedQuestion.jawaban_count &&
                      !jawaban.selected
                        ? ''
                        : 'cursor-pointer bg-hover-light-primary text-hover-primary'
                    } border ${
                      jawaban.selected
                        ? 'bg-light-primary text-primary border-primary'
                        : ''
                    }`"
                    v-for="(jawaban, i) in selectedQuestion.answer"
                    :key="`${selectedQuestion._id}${i}`"
                  >
                    <div
                      class="card-body d-flex flex-row p-4 align-items-center"
                    >
                      <b-form-checkbox
                        disabled
                        :name="`jawaban${selectedQuestion._id}`"
                        v-model="jawaban.selected"
                        size="lg"
                        class="align-items-start cursor-pointer"
                        v-if="selectedQuestion.type == 'multiple answer'"
                      ></b-form-checkbox>
                      <b-form-radio
                        :name="`jawaban${selectedQuestion._id}`"
                        :value="jawaban._id"
                        v-model="selectedQuestion.id_jawaban"
                        size="lg"
                        class="align-self-start cursor-pointer"
                        v-else
                      ></b-form-radio>
                      <div v-html="jawaban.answer"></div>
                    </div>
                  </div>
                </template>
                <b-form-group
                  v-if="selectedQuestion.type == 'essay'"
                  label="Jawaban"
                >
                  <b-form-textarea
                    :id="`textarea-jawaban-${i}`"
                    v-model="selectedQuestion.jawaban"
                    rows="3"
                    @input="persist"
                  ></b-form-textarea>
                </b-form-group>
              </div>
            </div>
            <div>
              <b-row class="justify-content-between">
                <b-col>
                  <b-button v-if="previousVisible" @click="previous">
                    previous
                  </b-button>
                </b-col>
                <b-col class="row justify-content-end">
                  <b-button v-if="nextVisible" @click="next"> next </b-button>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </b-col>
      <b-col>
        <div class="card card-custom card-stretch gutter-b">
          <div class="card-header border-0 py-5">
            <b-row class="justify-content-center">
              <b-col
                cols="2"
                class="m-2 text-center"
                v-for="(soal, i) in questions"
                :key="soal._id"
              >
                &nbsp;
                <span
                  @click="changeSoal(i)"
                  :class="`btn btn-icon btn-sm symbol ${
                    selectedQuestion._id == soal._id
                      ? 'btn-light-warning active'
                      : (
                          soal.type == 'essay'
                            ? soal.jawaban !== undefined
                              ? soal.jawaban.length > 0
                              : false
                            : soal.answer.filter((item) => item.selected)
                                .length > 0
                        )
                      ? soal.flagged
                        ? 'btn-light-info active'
                        : 'btn-light-primary' + ' active'
                      : soal.flagged
                      ? 'btn-light-info active'
                      : 'btn-secondary'
                  }`"
                >
                  <span
                    :class="`svg-icon svg-icon-md ${
                      soal.flagged ? 'svg-icon-light' : ''
                    }`"
                  >
                    {{ i + 1 }}
                  </span>
                </span>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import { SIDE_BAR } from "@/core/services/store/htmlclass.module";

import { EXAM_START } from "@/core/services/store/ujian.module";

import { mapGetters, mapState } from "vuex";
import {
  GRADE_ATTEMPT,
  SET_ONE_ATTEMPT,
  SET_STATE_ATTEMPT,
  QUESTION,
} from "@/core/services/store/attempt.module";
import Swal from "sweetalert2";
// import {UPDATE_ATTEMPT} from "@/core/services/store/attempt.module";

export default {
  name: "AttemptSoal",
  created() {},
  watch: {
    selectedIndex: function (newVal) {
      const maxSize = this.questions.length - 1;

      if (newVal == 0) {
        this.previousVisible = false;
      } else {
        this.previousVisible = true;
      }

      if (newVal == maxSize) {
        this.nextVisible = false;
      } else {
        this.nextVisible = true;
      }

      this.selectedQuestion = this.questions[newVal];
    },
    selectedQuestion: function () {},
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.currentAttempt.name }]);
    var body = KTUtil.getById("kt_wrapper");

    this.$store.dispatch(EXAM_START, true);
    this.$store.dispatch(SIDE_BAR, false);

    this.questions = this.currentAttempt.question_pack_id.questions;
    this.selectedQuestion = this.questions[this.selectedIndex];

    if (this.questions.length > 0) {
      this.nextVisible = true;
    }

    KTUtil.removeClass(body, "wrapper");
    this.$store.commit(SET_STATE_ATTEMPT, { attempting: true });
    if (this.now >= this.currentAttempt.target) {
      Swal.fire({
        title: "",
        text: "Waktu ujian sudah habis",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((value) => {
        if (value) {
          this.penilaian(this.currentAttempt.time);
        }
      });
    } else {
      this.interval = window.setInterval(() => {
        this.now = Math.trunc(new Date().getTime() / 1000);
        if (this.now >= this.currentAttempt.target) {
          clearInterval(this.interval);
          Swal.fire({
            title: "",
            text: "Waktu ujian sudah habis",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then((value) => {
            if (value) {
              this.penilaian(this.currentAttempt.time);
            }
          });
        }
      }, 1000);
    }
  },
  destroyed() {
    var body = KTUtil.getById("kt_wrapper");
    KTUtil.addClass(body, "wrapper");
    clearInterval(this.interval);
    this.$store.dispatch(SIDE_BAR, true);
    this.$store.commit(SET_STATE_ATTEMPT, { attempting: false });
    this.$store.dispatch(EXAM_START, false);
  },
  data() {
    return {
      now: Math.trunc(new Date().getTime() / 1000),
      interval: null,
      selectedIndex: 0,
      sliding: null,
      questions: [],
      selectedQuestion: {},
      nextVisible: false,
      previousVisible: false,
      exam_type: {
        default: "default",
        ices: "ices",
      },
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.attempt.errors,
    }),
    isAttempt() {
      return this.$route.name;
    },
    ...mapGetters(["currentAttempt"]),
    seconds() {
      return (this.currentAttempt.target - this.now) % 60;
    },
    minutes() {
      return Math.trunc((this.currentAttempt.target - this.now) / 60) % 60;
    },
    hours() {
      return Math.trunc((this.currentAttempt.target - this.now) / 60 / 60) % 24;
    },
    examType() {
      return this.currentAttempt.exam_type
        ? this.currentAttempt.exam_type.toLowerCase()
        : "-";
    },
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
    persist() {
      this.$store.commit(SET_ONE_ATTEMPT, this.currentAttempt);
    },
    addToAnswer(jawaban, answer) {
      const isExist = jawaban.filter((item) => item == answer).length > 0;
      if (isExist) {
        jawaban.remo;
      }

      if (jawaban.includes(answer)) {
        jawaban.splice(jawaban.indexOf(answer), 1);
      } else {
        jawaban.push(answer);
      }
    },
    submitUjian() {
      this.$bvModal
        .msgBoxConfirm(
          "Waktu ujian masih tersisa, apakah anda yakin ingin selesai mengerjakan?",
          {
            title: "Konfirmasi",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Ya",
            cancelTitle: "Tidak",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.penilaian(
              this.currentAttempt.time -
                Math.floor(
                  Math.abs(new Date() - this.currentAttempt.end) / 60000
                )
            );
          }
        });
    },
    penilaian(elapsed) {
      const overlay = this.$loadingOverlay.show();

      var question = [];
      this.questions.forEach((value, index) => {
        var myAnswer = [];
        var item = {};
        if (value.type != "essay") {
          value.answer
            .filter((item) => item.selected == true)
            .forEach((item) => {
              myAnswer.push(item._id);
            });

          item = {
            _id: value._id,
            answer: myAnswer,
          };
        } else {
          item = {
            _id: value._id,
            answer: value.jawaban,
          };
        }

        question.push(item);
      });

      var _data = {};

      _data._id = this.currentAttempt._id;
      _data.body = {
        remaining_time: `${elapsed}`,
        answer: question,
      };

      this.$store
        .dispatch(GRADE_ATTEMPT, _data)
        .then(() => {
          overlay.hide();
          Swal.fire({
            title: "",
            text: "Submit ujian berhasil!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.$router.replace("ujian");
        })
        .catch(() => {
          overlay.hide();

          Swal.fire({
            title: "",
            text: this.errors,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then((value) => {
            if (value) {
              this.$router.push("/ujian");
            }
          });

          this.$store.dispatch(EXAM_START, false);

          // this.$bvToast.toast(this.errors, {
          //   title: `Gagal Memuat Ujian`,
          //   variant: "danger",
          //   solid: true,
          // });
        });
    },
    next() {
      this.selectedIndex++;
    },
    previous() {
      if (this.selectedIndex - 1 >= 0) {
        this.selectedIndex--;
      }
    },
    changeJawaban(item, soal) {
      if (soal.type == "multiple answer") {
        this.$set(item, "selected", !item.selected);
      } else {
        soal.answer.forEach((jawab) => {
          this.$set(jawab, "selected", false);
        });
        this.$set(soal, "id_jawaban", item._id);
        this.$set(item, "selected", true);
      }

      this.$store.commit(SET_ONE_ATTEMPT, this.currentAttempt);
      this.$store.commit(QUESTION, this.questions);
    },
    chooseJawaban(answer, soal) {
      // FIXME : jawaban tidak tersimpan
      //FIXME : jawaban tidak bisa di uncheck
      //FIXME : btn next di akhir soal jadi btn selesai

      if (this.examType == "default") {
        if (
          soal.type == "multiple answer" ||
          soal.type == "bool" ||
          (soal.type == "multiple choice" &&
            soal.answer.filter((item) => item.selected).length !=
              soal.count_correct_answer &&
            !answer.selected)
        ) {
          this.changeJawaban(answer, soal);
        }
      } else {
        this.changeJawaban(answer, soal);
      }
    },
    changeFlagged() {
      this.selectedQuestion.flagged = !this.selectedQuestion.flagged;
      this.questions[this.selectedIndex] = this.selectedQuestion;
    },
    scrollToSoal(i) {
      const el = this.$el.getElementsByClassName(`soal-${i}`)[0];
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    changeSoal(i) {
      this.selectedIndex = i;
    },
    confirmationBack() {
      this.$bvModal
        .msgBoxConfirm(
          "Apakah anda yakin akan kembali ke halaman sebelumnya?",
          {
            title: "Konfirmasi",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Ya",
            cancelTitle: "Tidak",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.$router.push("/ujian");
          }
        });
    },
    // changeJawaban(value, item){
    //
    // }
  },
};
</script>

<style lang="scss"></style>
